import * as Types from '../../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLogReportQueryVariables = Types.Exact<{
  dateRange: Types.DateRangeOptions;
}>;


export type GetLogReportQuery = { getLogReport: { count: any, averageCharacterCount: number, averageDurationInSeconds: number, averageLogRating: number, countByType: Array<{ type: Types.LogType, count: any }>, countByStatus: Array<{ status: Types.LogStatus, count: any }>, countByTag: Array<{ tag: Types.LogTag, count: any }> }, getAuthorLogReport: Array<{ author: string, count: any, averageCharacterCount: number, averageDurationInSeconds: number }> };


export const GetLogReportDocument = gql`
    query GetLogReport($dateRange: DateRangeOptions!) {
  getLogReport(dateRange: $dateRange) {
    count
    countByType {
      type
      count
    }
    countByStatus {
      status
      count
    }
    countByTag {
      tag
      count
    }
    averageCharacterCount
    averageDurationInSeconds
    averageLogRating
  }
  getAuthorLogReport(dateRange: $dateRange) {
    author
    count
    averageCharacterCount
    averageDurationInSeconds
  }
}
    `;

/**
 * __useGetLogReportQuery__
 *
 * To run a query within a React component, call `useGetLogReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogReportQuery({
 *   variables: {
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useGetLogReportQuery(baseOptions: Apollo.QueryHookOptions<GetLogReportQuery, GetLogReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogReportQuery, GetLogReportQueryVariables>(GetLogReportDocument, options);
      }
export function useGetLogReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogReportQuery, GetLogReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogReportQuery, GetLogReportQueryVariables>(GetLogReportDocument, options);
        }
export type GetLogReportQueryHookResult = ReturnType<typeof useGetLogReportQuery>;
export type GetLogReportLazyQueryHookResult = ReturnType<typeof useGetLogReportLazyQuery>;
export type GetLogReportQueryResult = Apollo.QueryResult<GetLogReportQuery, GetLogReportQueryVariables>;