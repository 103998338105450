import { useState, useEffect } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  Column,
  HeaderGroup,
  IdType
} from 'react-table';
import {
  Card,
  CardBody,
  CardFooter,
  Table as ReactstrapTable,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { Search } from 'react-feather';
import { Pulse } from '../Pulse';
import { Pagination, PaginationProps } from '../Pagination';

export type TableProps<T extends object> = {
  data: T[];
  loading?: boolean;
  columns?: Array<Column<T>>;
  children?: JSX.Element;
  handleSelect?: (item: T) => void;
  striped?: boolean;
  sortBy?: {
    id: IdType<T>;
    desc: boolean;
  };
  disableHover?: boolean;
  disableSearch?: boolean;
  content?: JSX.Element;
  pagination?: PaginationProps;
  pageSize?: number;
};

export const Table = <T extends object>(props: TableProps<T>) => {
  const {
    data,
    loading,
    columns = [],
    children,
    handleSelect,
    sortBy,
    disableHover = false,
    disableSearch = false,
    striped,
    content,
    pagination,
    pageSize = 10
  } = props;

  useEffect(() => {
    handleSearch(search);
  }, [data]);

  const [search, setSearch] = useState('');

  const handleSearch = (value: string) => {
    // gotoPage(0);

    setSearch(value);

    setGlobalFilter(value);
  };

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    state
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageSize,
        ...(sortBy && { sortBy: [sortBy] })
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const generateSortingIndicator = (column: HeaderGroup<T>) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return <span className="sort-by desc" />;
      }
      return <span className="sort-by asc" />;
    }

    if (column.canSort) {
      return <span className="sort-by" />;
    }

    return null;
  };

  return (
    <Card>
      <CardBody>
        <Row className="pb-4">
          {!disableSearch && (
            <Col lg="4">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Search color="#606E80" size={18} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={search}
                    required
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          )}
          <Col className="text-right">{children}</Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
        {!loading && (
          <div className="table-responsive">
            <ReactstrapTable striped={striped}>
              <thead>
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderGroupProps } =
                    headerGroup.getHeaderGroupProps();

                  return (
                    <tr key={key} {...restHeaderGroupProps}>
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps(
                          column.getSortByToggleProps()
                        );

                        return (
                          <th key={key} {...restColumn}>
                            {column.render('Header')}
                            {generateSortingIndicator(column)}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();
                  let isDeactivated = false;
                  if ('deactivatedAt' in row.original) {
                    isDeactivated = !!row.original.deactivatedAt;
                  }
                  return (
                    <tr
                      style={
                        disableHover
                          ? { backgroundColor: 'white', cursor: 'default' }
                          : {}
                      }
                      className={isDeactivated ? 'text-muted' : ''}
                      key={key}
                      onClick={() => handleSelect?.(row.original)}
                      {...restRowProps}
                    >
                      {row.cells.map((cell) => {
                        const { key, ...restCellProps } = cell.getCellProps();
                        return (
                          <td key={key} {...restCellProps}>
                            <div
                              style={{
                                maxHeight: '200px',
                                overflowY: 'scroll'
                              }}
                            >
                              {cell.render('Cell')}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </ReactstrapTable>
          </div>
        )}
        {page.length === 0 && !loading && search && (
          <p className="mb-0 mt-4 text-center">
            No records found, try adjusting your search.
          </p>
        )}
        {page.length === 0 && !loading && !search && (
          <p className="mb-0 mt-4 text-center">No records found</p>
        )}
        {loading && <Pulse />}
      </CardBody>
      {page.length !== 0 && (
        <CardFooter>
          <Pagination
            totalCount={data.length}
            pageCount={pageCount}
            currentPage={state.pageIndex}
            goToPage={gotoPage}
            hasNextPage={canNextPage}
            hasPreviousPage={canPreviousPage}
            {...pagination}
          />
        </CardFooter>
      )}
    </Card>
  );
};
